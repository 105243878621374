<template>
  <div class="main">
    <div class="page-box">
      <div class="page-title">沙特籍培训费用</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breadcrumb-active"
          ><i class="fa fa-dashboard"></i>工作台</el-breadcrumb-item
        >
        <el-breadcrumb-item class="breadcrumb-active"
          >费用管理</el-breadcrumb-item
        >
        <el-breadcrumb-item class="breadcrumb-active"
          >费用管理</el-breadcrumb-item
        >
        <el-breadcrumb-item>沙特籍培训费用</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="card-box">
      <div class="screen-box">
        <div class="screen-input-box">
          <div class="title-box">费用项目:</div>
          <el-input
            v-model="categoryName"
            placeholder="请输入费用项目"
          ></el-input>
        </div>

        <div class="screen-input-box">
          <div class="title-box">费用期间:</div>
          <el-date-picker
            v-model="duration"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>

        <button class="btn-pre" @click="getSaudiTrainingCosts(1)">
          <i class="fa fa-search"></i>查询
        </button>
        <button class="btn-pre" @click="getSaudiTrainingCostExport">
          <i class="fa fa-download"></i>导出
        </button>
      </div>
      <div class="table-box">
        <el-table
          :data="tableData"
          border
          height="500"
          tooltip-effect="light"
          :header-cell-style="{ color: '#f5f5f5', background: '#23527c' }"
          :empty-text="loading ? '加载中...' : '暂无数据'"
        >
          <el-table-column label="#" width="35">
            <template slot-scope="scope">
              <el-checkbox></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column prop="ID" label="id" width="40" />
          <el-table-column
            prop="CostCategoryName"
            label="费用项目"
            width="100"
          />
          <el-table-column prop="Amount" label="费用金额" width="80">
          </el-table-column>
          <el-table-column prop="Duration" label="费用期间" width="100" />
          <el-table-column prop="InvoiceNumber" label="发票号" width="80" />
          <el-table-column prop="BankInformation" label="收款单位" width="80" />
          <el-table-column prop="BankAccountName" label="名称" width="80" />
          <el-table-column prop="BankName" label="开户行" width="80" />
          <el-table-column prop="BankAccount" label="账号" width="80" />
        </el-table>

        <el-pagination
          @size-change="getSaudiTrainingCosts(1)"
          @current-change="getSaudiTrainingCosts"
          :current-page.sync="page"
          :page-size.sync="size"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { auditRecords } from "@/utils/tableData";
import {
  getSaudiTrainingCosts,
  getProjectAll,
  getCompanyAll,
  getDepartmentAll,
  getSaudiTrainingCostExport,
} from "@/api/user";
import CostTable from "@/components/tables/costTable.vue";
export default {
  data() {
    return {
      tableData: [],
      tableData2: [],
      multipleSelection: [],
      auditRecords: auditRecords,
      dialogVisible: false,
      dialogVisible2: false,
      dialogType: 1, //1新建2、编辑
      options1: null,
      disabled: false,
      senior: false,
      invoiceNumber: null,
      costCenterCode: null,
      duration: null,
      projectId: null,
      companyId: null,
      deptId: null,
      projectList: [],
      companyList: [],
      departmentList: [],
      value3: null,
      value4: null,
      value5: null,
      value6: null,
      radio: "1",
      statesRadio: "",
      textarea: "",
      roleName: "",
      enable: false,
      page: 1,
      size: 10,
      total: 10,
      Id: 0,
      ParentId: 0,
      DepartmentId: 0,
      powers: {
        add: false,
        update: false,
        delete: false,
        download: false,
        import: false,
        export: false,
        print: false,
        examine: false,
        upload: false,
        resetting: false,
      },
      name: null,
      EditState: 1,
      KeyID: null,
      FormID: null,
      categoryName: null,
      loading: true,
    };
  },
  components: { CostTable },
  methods: {
    getButtonPower() {
      if (!this.$store.state.userPower) {
        this.$store.commit(
          "changeUserPower",
          JSON.parse(localStorage.getItem("userPower"))
        );
      }

      const item = this.$store.state.userPower.find((item) => {
        return item.Name === this.$route.meta.title;
      });
      const actions = item.Actions;

      actions.forEach((element) => {
        switch (element.Id) {
          case 1: //增加
            this.powers.add = true;
            break;
          case 2: //删除
            this.powers.delete = true;
            break;
          case 3: //修改
            this.powers.update = true;
            break;
          case 5: //下载
            this.powers.download = true;
            break;
          case 6: //导入
            this.powers.import = true;
            break;
          case 7: //导出
            this.powers.export = true;
            break;
          case 8: //打印
            this.powers.print = true;
            break;
          case 9: //审批
            this.powers.examine = true;
            break;
          case 10: //上传
            this.powers.upload = true;
            break;
          case 11: //重置
            this.powers.resetting = true;
            break;
        }
      });
    },
    //获取列表
    getSaudiTrainingCosts(type) {
      if (type) {
        this.page = type;
      }
      let startDate = null;
      let endDate = null;
      this.loading = true;
      if (this.duration) {
        startDate = this.duration[0];
        endDate = this.duration[1];
      }
      const data = {
        page: this.page,
        size: this.size,
        categoryName: this.categoryName,
        startDate: startDate,
        endDate: endDate,
      };
      getSaudiTrainingCosts(data).then((res) => {
        if (res.status === 200) {
          this.tableData = res.response.data;
          this.total = res.response.dataCount;
        }
        this.loading = false;
      });
    },
    //导出
    getSaudiTrainingCostExport() {
      let startDate = null;
      let endDate = null;
      if (this.duration) {
        startDate = this.duration[0];
        endDate = this.duration[1];
      }
      const data = {
        categoryName: this.categoryName,
        startDate: startDate,
        endDate: endDate,
      };
      getSaudiTrainingCostExport(data).then((res) => {
        if (res.status === 200) {
          let url = res.response;
          let charIndex = url.indexOf("Download");
          url = url.substr(charIndex, url.length);
          url = url.replace(/\\/g, "/");
          location.href = "https://hrms.baishulai.com/" + url;
        }
      });
    },
  },

  created() {
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    getDepartmentAll().then((res) => {
      if (res.status === 200) {
        this.departmentList = res.response;
      }
    });
    this.getSaudiTrainingCosts();
    this.getButtonPower();
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-color: #f7f8fa;
  .page-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .page-title {
      font-size: 18px;
      color: #000000;
    }
    .breadcrumb-active {
      color: #303133;
      font-weight: bolder;
      cursor: pointer;
      .fa {
        margin-right: 5px;
      }
    }
  }
  .card-box {
    width: 100%;
    // min-height: calc(100% - 48px);
    // height: calc(100% - 48px);
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    margin-top: 24px;
    box-sizing: border-box;
    padding: 0 0 24px 0;
    .screen-box {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      padding: 0 10px;
      .screen-input-box {
        margin-top: 24px;
      }
      .btn-pre {
        margin-top: 24px;
      }
      .btn-add {
        margin-top: 24px;
      }
      .el-input {
        width: 150px;
        margin-right: 24px;
      }
      .el-button {
        margin-right: 24px;
      }
      .el-select {
        width: 150px;
        margin-right: 24px;
      }
      .el-date-editor {
        margin-right: 24px;
      }
      .senior {
        position: absolute;
        width: 100%;
        top: 40px;
        left: 0;
        background: #fefefe;
        border: 1px solid #eae8e8;
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: rgb(234, 232, 232);
        box-sizing: border-box;
        z-index: 999;
        padding: 0 24px 24px 24px;
        border-top: 0;
        box-shadow: 0 7px 18px -12px #bdc0bb;
        .from-boxs {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .from-box {
            margin-top: 24px;
            .name {
              margin-bottom: 10px;
            }
            .el-select {
              width: 150px;
            }
            .el-input {
              width: 150px;
            }
          }
        }
        .buttons {
          display: flex;
          justify-content: flex-end;
          margin-top: 24px;
        }
      }
    }
    .table-box {
      width: 100%;
      // height: calc(100% - 143px);
      .el-table {
        margin-top: 24px;
        // height: calc(100% - 56px);
        min-height: 500px;
        box-sizing: border-box;
      }
      .el-pagination {
        margin-top: 24px;
      }
      .table-color {
        color: #0076ff;
      }
      .buttons {
        display: flex;
      }
      .fa {
        margin-right: 10px;
        color: #3c8dbc;
        cursor: pointer;
      }
      .fa:hover {
        color: #72afd2;
      }
    }
  }
  .upload-box {
    display: flex;
    height: 34px;
    margin-top: 24px;
    .left {
      width: 100%;
      border: 1px solid #d2d6de;
      border-right: none;
      .name {
        display: flex;
        align-items: center;
        height: 34px;
        color: #555;
        font-size: 14px;
        text-indent: 2px;
      }
    }
    .btn-up {
      padding: 0;
      margin: 0;
      border: none;
      width: 90px;
      flex-shrink: 0;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      background-color: #3c8dbc;
      border: 1px solid #367fa9;
      color: #fff;
      font-size: 14px;
      .icon {
        margin-right: 5px;
      }
    }
    .disabled {
      cursor: no-drop;
    }
    .btn-up:hover {
      border-color: #204d74;
      background-color: #367fa9;
    }
  }
  .dialog-main {
    border-bottom: 1px solid #f4f4f4;
    padding: 12px 24px 0 24px;
    width: 100%;
    box-sizing: border-box;
    .line-box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .from-box {
        display: flex;
        align-items: center;
        width: 330px;
        margin-bottom: 24px;
        .name {
          width: 80px;
          font-size: 16px;
          flex-shrink: 0;
        }
        .el-cascader {
          width: 250px;
        }
        .el-input {
          width: 250px;
        }
      }
    }
  }
  .dialog-footer {
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .el-button {
      height: 34px;
      padding: 0 20px;
      margin-right: 12px;
      margin-left: 0;
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-table {
  width: 100%;
  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }
  .el-table__body,
  .el-table__footer,
  .el-table__header {
    table-layout: auto;
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
  .el-table__header {
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
}
</style>
<style scoped>
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 0;
  overflow: auto;
  border-top: 1px solid #f4f4f4;
}
.el-dialog__wrapper >>> .my-label {
  background: #f5f7fa !important;
}
.el-textarea >>> .el-textarea__inner {
  min-height: 100px !important;
}
.el-table >>> .el-table__placeholder {
  width: 23px;
}
.table-box >>> .el-table__empty-block {
  width: 100% !important;
}
.el-table >>> th.el-table__cell > .cell {
  font-weight: bolder;
  font-size: 12px;
  text-align: center;
  font-family: "Microsoft YaHei";
}
.tableData2 >>> .el-table__header .cell {
  font-size: 14px !important;
  font-weight: bolder !important;
}

.tableData2 >>> .el-table__empty-block {
  width: 100% !important;
}

.el-table >>> th.el-table__cell {
  padding: 6px 0;
}

.el-table >>> .el-table__body .cell {
  text-align: center;
  font-size: 12px;
  color: #333;
}
.el-table >>> .el-table__cell {
  padding: 6px 0;
}
.approval {
  display: inline;
  padding: 2px 5px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  font-size: 12px;
}
.label-success {
  background-color: #00a65a;
}
.label-warning {
  background-color: #f39c12;
}
.fa {
  margin-right: 10px;
  color: #3c8dbc;
  cursor: pointer;
}
.fa:hover {
  color: #72afd2;
}
.screen-box >>> .el-input__inner {
  height: 34px;
  border-color: #d2d6de;
  border-radius: 0;
}
.screen-box >>> .el-input__inner:focus {
  height: 34px;
  border-color: #61a2c9;
  border-radius: 0;
}
.card-upload >>> .el-upload-list {
  display: none;
}
.dialog >>> .el-dialog__body {
  padding: 15px 15px 0 15px;
}
</style>
